<template>
  <div>
    <el-menu-item index="home" @click="$router.push({ name: 'home' })">
      <i name="shouye" class="el-icon-s-home"></i>
      <span slot="title" style="font-size: 16px">首页</span>
    </el-menu-item>

    <el-submenu index="information">
      <template slot="title">
        <i name="info" class="el-icon-s-custom"></i>
        <span style="font-size: 16px">养老院管理</span>
      </template>
      <el-menu-item
          index="stationPage1"
          @click="$router.push({ name: 'memberData_station' })"
      >
        <i name="tubiao" class="el-icon-search"></i>
        <span slot="title" style="font-size: 16px">成员管理</span>
      </el-menu-item>
      <!-- <el-menu-item
          index="stationPage2"
          @click="$router.push({ name: 'relaCheck' })"
      >
        <i name="tubiao" class="el-icon-search"></i>
        <span slot="title" style="font-size: 16px">体检情况</span>
      </el-menu-item> -->
    </el-submenu>
    <el-submenu index="remote">
      <template slot="title">
        <i name="info" class="el-icon-s-custom"></i>
        <span style="font-size: 16px">远程诊疗</span>
      </template>
    <el-menu-item index="remotePage3"
                  @click="$router.push({ name: 'remotePage3' })">
      <i name="info" class="el-icon-s-cooperation"></i>
      <span slot="title" style="font-size: 16px">远程诊疗预约</span>
    </el-menu-item>
      <el-menu-item index="remoteHistory1"
                    @click="$router.push({ name: 'remoteHistory1' })">
        <i name="info" class="el-icon-s-cooperation"></i>
        <span slot="title" style="font-size: 16px">预约、问诊历史</span>
      </el-menu-item>

      <el-menu-item index="emergency1"
                    @click="$router.push({ name: 'emergency1' })">
        <i name="info" class="el-icon-s-cooperation"></i>
        <span slot="title" style="font-size: 16px">急诊</span>
      </el-menu-item>
    </el-submenu>

    <el-menu-item index="home" @click="toBigScreen()">
      <i name="shouye" class="el-icon-s-home"></i>
      <span slot="title" style="font-size: 16px">一屏管理</span>
    </el-menu-item>

    <!-- <el-menu-item index="home" @click="$router.push({ name: 'healthPolularization' })">
      <i name="shouye" class="el-icon-s-home"></i>
      <span slot="title" style="font-size: 16px">健康科普</span>
    </el-menu-item>
    <el-menu-item index="home" @click="$router.push({ name: 'healthPolularization' })">
      <i name="shouye" class="el-icon-s-home"></i>
      <span slot="title" style="font-size: 16px">健康指导</span>
    </el-menu-item> -->
<!--    <el-submenu index="accountSetting">
      <template slot="title">
        <i name="info" class="el-icon-set-up"></i>
        <span style="font-size: 16px">账户设置</span>
      </template>
      <el-menu-item
          index="accountPage5"
          @click="$router.push({ name: 'accountPage5' })"
      >
        <i name="tubiao" class="el-icon-search"></i>
        <span slot="title" style="font-size: 16px">账户信息</span>
      </el-menu-item>
      <el-menu-item
          index="accountPage6"
          @click="$router.push({ name: 'accountPage6' })"
      >
        <i name="tubiao" class="el-icon-search"></i>
        <span slot="title" style="font-size: 16px">修改密码</span>
      </el-menu-item>
    </el-submenu>-->


  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "www.xin-gou.com/older_big_screen/",
      dialogVisible: false
    };
  },
  components: {
    // SubMenu
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  methods: {

    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            done(
                this.dialogVisible = false
            )
            // window.location.href = "http://" + this.url;
          })
          .catch(() => {});
    },

    // 跳转到大屏
    toBigScreen() {
      this.dialogVisible = false
      window.location.href = "http://" + this.url;
    }
  },
};
</script>

<style scoped>
/* .site-sidebar__menu template span {
  font-size: 16px;
} */
</style>
